import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table, notification, Modal, Select, Avatar, Typography } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { UserOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import FilterReport from "./FilterReport";

const { Option } = Select;
const { Title } = Typography;

const createStyledTitle = (text) => (
  <span style={{ fontSize: '15px', color: '#0056b3' }}>{text}</span>
);

// Table columns definition
const columns = [
  {
    title: createStyledTitle("SR.no"),
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: createStyledTitle("Name"),
    dataIndex: "name",
    key: "name",
    fixed: 'left',
    width: 80,
  },
  {
    title: createStyledTitle("Phone no."),
    dataIndex: "phone_no",
    key: "phone_no",
  },
  {
    title: createStyledTitle("Father Name"),
    dataIndex: "father_name",
    key: "father_name",
  },
  {
    title: createStyledTitle("Monthly Share"),
    dataIndex: "monthly_share",
    key: "monthly_share",
  },
  {
    title: createStyledTitle("Total Announce Amount"),
    dataIndex: "total_announced_amount",
    key: "total_announced_amount",
  },
  {
    title: createStyledTitle("Received Amount"),
    dataIndex: "total_received_amount",
    key: "total_received_amount",
  },
  {
    title: createStyledTitle("Due Amount"),
    dataIndex: "total_due_amount",
    key: "total_due_amount",
  },
  {
    title: createStyledTitle("Total Samarpan"),
    dataIndex: "total_samarpan",
    key: "total_samarpan",
  },

  {
    title: createStyledTitle("Last Payment Date"),
    dataIndex: "last_payment_date",
    key: "last_payment_date",
  },
  {
    title: createStyledTitle("Last Payment Status"),
    dataIndex: "last_payment_virify",
    key: "last_payment_virify",
  },
  {
    title: createStyledTitle("Last Response"),
    key: "latest_payment_response",
    dataIndex: "latest_payment_response",
  },
  {
    title: createStyledTitle("Joining Date"),
    dataIndex: "joining_date",
    key: "joining_date",
  },
];

function ManualReport() {
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    createdBy: ''
  });

  useEffect(() => {
    getUserList();
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 60, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/users/manualReport?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleLimitChange = value => {
    setLimit(value);
    getUserList(1, value, sortField, sortOrder, search, filters);
  };

  const debouncedGetUserList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {
    getUserList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);

  useEffect(() => {
    const filterKeys = Object.keys(filters);

    // Check if any filter other than 'createdBy' has a length of 3 or more
    const anyFilterHasMinLength = filterKeys.some(
      key => key !== 'createdBy' && filters[key].length >= 0
    );

    // Check if all filters are either empty or specifically 'createdBy'
    const allFiltersEmptyOrCreatedBy = filterKeys.every(
      key => key === 'createdBy' || !filters[key]
    );

    if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {
      debouncedGetUserList(currentPage, limit, sortField, sortOrder, search, filters);
    }
  }, [filters, currentPage, limit, sortField, sortOrder, search]);

  //For show image on model large
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Manually Report"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <FilterReport onFiltersChange={handleFiltersChange} />
                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={60} onChange={handleLimitChange}>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                          
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}
                dataSource={userList.map((user, index) => ({
                  key: (limit * (currentPage - 1)) + index + 1,
                  name: (
                    <>
                      <Avatar.Group>
                        <Avatar
                          className="shape-avatar"
                          shape="square"
                          size={40}
                          src={user.profile_img ? baseUrl + '/' + user.profile_img : userDummy}
                          icon={!user.profile_img && <UserOutlined />}
                          onClick={() => showModal(user.profile_img ? baseUrl + '/' + user.profile_img : userDummy)}
                          style={{ cursor: 'pointer' }}
                        />
                        <div className="avatar-info">
                          <Title level={5}>
                            <Link to={`/users/view/${user.mamber_id}`}  >
                              {user.name}
                            </Link>
                          </Title>
                          <p>{user.email_address}</p>
                        </div>
                      </Avatar.Group>{" "}
                    </>
                  ),
                  phone_no: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.phone_no}</Title>
                        <p>{user.alternate_phone_no}</p>
                      </div>
                    </>
                  ),
                  father_name: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {user.relation_hn }</Title>
                        <p>
                          {user.father_name_en + (user.father_name_hn ? ' / ' + user.father_name_hn : '')}
                        </p>
                      </div>
                    </>
                  ),
                  monthly_share: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {user.announced_amount ? 'Rs.'+user.announced_amount : '-'}  </Title>
                      </div>
                    </>
                  ),
                  total_announced_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.total_announced_amount ? 'Rs.'+user.total_announced_amount : '-'}</Title>
                      </div>
                    </>
                  ),
                  total_received_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.total_received_amount ? 'Rs.'+user.total_received_amount : '-'}</Title>
                      </div>
                    </>
                  ),
                  total_due_amount: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.total_due_amount ? 'Rs.'+user.total_due_amount: '-'}</Title>
                      </div>
                    </>
                  ),
                  last_payment_virify: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.last_payment_virify ? user.last_payment_virify : '-'}</Title>
                      </div>
                    </>
                  ),
                  last_payment_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.last_payment_date ? moment(user.last_payment_date).format('DD MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                  latest_payment_response: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.latest_payment_response}</Title>
                      </div>
                    </>
                  ),
                  joining_date: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.joining_date ? moment(user.joining_date).format('DD MMMM YYYY') : '-'}</Title>
                      </div>
                    </>
                  ),
                }))}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>
    </div>
  );
}

export default ManualReport;
