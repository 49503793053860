import React, { useEffect, useState } from "react";
import { Input, Form, DatePicker, Button, Row, Col, notification, Upload, Image, Typography, Avatar } from "antd";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../../config";
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddMessage = (data) => {
    const [form] = Form.useForm();
    // const [record, setRecord] = useState({});
    const [imageFile, setImageFile] = useState(null); // State for the uploaded image file
    const [imagePreview, setImagePreview] = useState(null); // State for the uploaded image preview
    const [imageHave, setImageHave] = useState(null); // State for the uploaded image preview

    // useEffect(() => {
    //     getLatestRecord();
    // }, []);

    useEffect(() => {
        form.setFieldsValue({
            purnima_img: data.record.purnima_img || "",
            scheduledDate: data.record.purnima_date_schedule_before ? moment(data.record.purnima_date_schedule_before) : null,
        });
        setImageHave(data.record.purnima_img)
    }, [form, data]);

    // async function getLatestRecord() {
    //     try {
    //         const response = await axios.get(`${baseUrl}/purnima-message-setting/read`, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    //             }
    //         });
    //         if (response.data.status) {
    //             if (response.data.result && response.data.result.purnimaImgRecords.length > 0) {
    //                 setRecord(response.data.result?.purnimaImgRecords[0]);
    //                 setImageHave(response.data.result?.purnimaImgRecords[0].purnima_img)
    //             }
    //         } else {
    //             notification.info({
    //                 message: 'Info',
    //                 description: response.data.message,
    //                 placement: 'topRight'
    //             });
    //         }
    //     } catch (error) {
    //         notification.info({
    //             message: 'Info',
    //             description: error.response?.data?.message,
    //             placement: 'topRight'
    //         });
    //     }
    // };

    const save = async () => {
        try {
            const values = await form.validateFields();
            handleSave({ ...data.record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    const handleSave = async (newMessage) => {
        try {
            const formData = new FormData();
            formData.append("file", imageFile); // Append the image file
            formData.append("purnima_day_date", newMessage.scheduledDate);

            const response = await axios.post(`${baseUrl}/purnima-message-setting/add-or-update`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    "Content-Type": "multipart/form-data", // Important for file uploads
                }
            });

            if (response.data.status) {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    };

    // Handle image upload
    const handleImageUpload = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result); // Set the image preview
        };
        reader.readAsDataURL(file);
        setImageFile(file); // Store the file for submission
        return false; // Prevent default upload behavior
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                purnima_img: data.record.purnima_img || "",
                scheduledDate: data.record.scheduledDate ? moment(data.record.scheduledDate) : null,
            }}
            style={{
                border: "1px solid #d9d9d9",
                padding: "16px",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
            }}
        >
            <Title level={5}>Purnima Day</Title>
            <Row gutter={16}>
                <Col span={4}>
                    <Form.Item
                        label="Upload Image"
                        name="purnima_img"
                        rules={[{ required: imageFile ? false : true, message: "Image is required" }]}
                    >
                        <Upload
                            beforeUpload={handleImageUpload}
                            showUploadList={false} // Hide the default upload list
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={2}>
                    {/* Check if there's an image preview or fallback to existing image */}
                    {imagePreview ? (
                        <Image
                            src={imagePreview}
                            alt="Uploaded Image" 
                            width={50} // Set width to display as an icon initially
                            height={50} // Set height for the icon
                            style={{ marginTop: 20, cursor: 'pointer', borderRadius:"10px" }}
                            preview={true} // Enable Ant Design image preview
                        />
                    ) : imageHave && (
                        <Image
                            src={`${baseUrl}/${imageHave}`}
                            alt="Uploaded Image"
                            width={50} // Set width to display as an icon initially
                            height={50} // Set height for the icon
                            style={{ marginTop: 20, cursor: 'pointer', borderRadius:"10px" }}
                            preview={true} // Enable preview
                        />
                    )}
                </Col>

                <Col span={6}>
                    <Form.Item
                        label="Scheduled Date (Purnima Day )"
                        name="scheduledDate"
                        rules={[{ required: true, message: "Scheduled Date is required" }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button type="primary" onClick={save} style={{ marginTop: '25px' }}>
                        Save Message Scheduled
                    </Button>
                </Col>
            </Row>



        </Form>
    );
};

export default AddMessage;
